import {MILLISECOND_IN_SECOND} from './constants/common';
import {startInterval} from './helpers/interval.js';
import {enableBrandingBehavior, enableMobileBrandingBehavior} from './modules/enable-branding-behavior.js';
import {ANALYTIC_EVENTS} from './services/analytics/pub-sub/constants';
import {publishEvent} from './services/analytics/pub-sub/index';
import {isInViewport} from './services/helpers';

const {targeting, targetingContentTypes} = window.Site;

const getCurrentTargeting = $target => {
  const {id} = $target?.closest?.('.js-main-wrapper')?.querySelector('.js-article')?.dataset || {};

  return targetingContentTypes?.[id] ? targetingContentTypes[id] : targeting;
};

const getCurrentSlotAdditionalparams = $target => {
  const article = $target.closest('.js-main-wrapper')?.querySelector('.js-article');

  if (!article) {
    return {};
  }

  const additionalParams = {};

  additionalParams.puid1 = article.dataset.puid1 ?? '';
  additionalParams.puid2 = article.dataset.puid2 ?? '';
  additionalParams.puid3 = article.dataset.puid3 ?? '';
  additionalParams.puid4 = article.dataset.puid4 ?? '';
  additionalParams.puid5 = article.dataset.puid5 ?? '';
  additionalParams.puid5 = article.dataset.puid5 ?? '';

  return additionalParams;
};

const setHeightImportant = (parentDocument, selector, height) => {
  const css = `${selector} { height: ${height} !important; }`;

  let styleSheet = document.getElementById('dynamic-styles');

  if (!styleSheet) {
    styleSheet = parentDocument.createElement('style');
    styleSheet.id = 'dynamic-styles';
    parentDocument.head.appendChild(styleSheet);
  }

  styleSheet.innerHTML = css;
};

const startSpecialProjectSlot = containerId => {
  if (containerId === 'js-adfox-special-project') {
    const adfoxBettingElement = document.querySelector('#js-adfox-special-project');

    const TILDA_HEADER_ELEMENTS = {
      '.t-cover': '700px',
      '.t-cover__carrier': '700px',
      '.t-cover__filter': '700px',
      '.t-cover__wrapper': '700px'
    };

    const observerCallback = mutationsList => {
      mutationsList.forEach(() => {
        const baseIframeElement = document.querySelector('#js-adfox-special-project > iframe');

        let specialProjectIframeElement = null;

        try {
          specialProjectIframeElement = baseIframeElement?.contentWindow.document.querySelector('iframe');
        } catch (error) {
          console.warn('Error accessing iframe content:', error);
        }

        if (specialProjectIframeElement) {
          baseIframeElement.style.display = 'block';
        } else {
          adfoxBettingElement.setAttribute('data-reload', 'true');
          baseIframeElement.style.display = 'none';
          return;
        }

        specialProjectIframeElement.addEventListener('error', () => {
          baseIframeElement.style.display = 'none';
        });

        specialProjectIframeElement.addEventListener('load', () => {
          // get new not live element via querySelector
          const adfoxIframeElement = document.querySelector('#js-adfox-special-project > iframe');

          let specialProjectElement = null;

          let specialProjectDocument = null;

          try {
            specialProjectElement = adfoxIframeElement?.contentWindow.document.querySelector('iframe');
            specialProjectDocument = specialProjectElement?.contentWindow?.document;
          } catch (error) {
            console.warn('Error accessing iframe content:', error);
          }

          if (specialProjectDocument) {
            // add default height to header element
            for (const [key, value] of Object.entries(TILDA_HEADER_ELEMENTS)) {
              setHeightImportant(specialProjectDocument, key, value);
            }

            // clear all hidden sources
            specialProjectDocument
              .querySelectorAll('.r_hidden')
              .forEach(element => element.classList.remove('r_hidden'));

            // add visible part of the iframe
            adfoxIframeElement.style.height = `${specialProjectDocument.body.scrollHeight}px`;
            adfoxIframeElement.style.display = 'block';
          }
        }, {once: true});
      });
    };

    const observer = new MutationObserver(observerCallback);

    observer.observe(adfoxBettingElement, {
      childList: true,
      attributes: true,
      subtree: true
    });
  }
};

const renderedSlots = {};

const addAdFoxAnalytics = (containerId, callbacks) => {
  const slotContainerElement = document.querySelector(`#${containerId}`);

  slotContainerElement.addEventListener('click', () => {
    publishEvent(ANALYTIC_EVENTS.ADFOX_TAP_ON_BANNER, {
      containerId
    });
  });

  const slotObserver = new IntersectionObserver(entries => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        publishEvent(ANALYTIC_EVENTS.ADFOX_PLACEMENT_IMPRESSION, {
          containerId
        });

        if (renderedSlots[containerId]) {
          publishEvent(ANALYTIC_EVENTS.ADFOX_CREATIVE_IMPRESSION, {
            containerId
          });
        }
      }
    });
  }, {threshold: 1});

  slotObserver.observe(slotContainerElement);

  return {
    onRender: () => {
      renderedSlots[containerId] = true;

      if (callbacks) {
        callbacks.onRender && callbacks.onRender();
      }
    },
    onError: error => {
      publishEvent(ANALYTIC_EVENTS.ADFOX_ERROR, {
        containerId,
        message: error
      });
    },
    onStub: () => {
      publishEvent(ANALYTIC_EVENTS.ADFOX_STUB, {
        containerId
      });
    }
  };
};

const loadDeferredAdfoxAds = $target => {
  const {id: slotId} = $target?.dataset || {};
  const {adfoxUnits = []} = window.Site;
  const slot = adfoxUnits.find(({containerId}) => containerId === slotId);
  const puid7 = $target.dataset.puid7 || '';

  if (slot) {
    const newContainerId = `${$target.id}`;
    const currentTargeting = getCurrentTargeting($target);
    const additionalParams = getCurrentSlotAdditionalparams($target);

    window.yaContextCb.push(() => {
      window.Ya.adfoxCode.create({
        ...slot,
        containerId: newContainerId,
        params: {
          ...slot.params,
          ...window.Site.adFoxParams,
          ...additionalParams,
          puid7,
          pk: [
            (currentTargeting.category || []).join(' '),
            (currentTargeting.tags || []).join(' '),
            currentTargeting.attr_sponsored_by || '',
            currentTargeting.attr_content_type || ''
          ].filter(Boolean).join(' '),
          pke: '1'
        },
        ...addAdFoxAnalytics(newContainerId),
        onLoad: () => {
          startSpecialProjectSlot(newContainerId);
        }
      });
    });

    if (slot.reloadInterval) {
      startInterval(() => window.Ya?.adfoxCode?.reload(newContainerId, {onlyIfWasVisible: false}),
        Number(slot.reloadInterval) * MILLISECOND_IN_SECOND);
    }
  }
};

const lazyLoadAds = () => {
  document.addEventListener('lazybeforeunveil', e => {
    if (e.target.getAttribute('data-lazyload-adfox-ad')) {
      loadDeferredAdfoxAds(e.target);
    }
  });
};

const onSlotRenderEnded = slotName => {
  if ((/js-adfox-anchor/).test(slotName)) {
    const $anchorCloseButton = document.querySelector('.js-advert-close');

    if (!$anchorCloseButton) {
      return;
    }

    $anchorCloseButton.classList.remove('advert-anchor-close--hidden');

    $anchorCloseButton.addEventListener('click', event => {
      event.stopPropagation();
      event.target.parentNode.classList.toggle('advert-anchor--collapsed');
    });
  }
};

/*
 * AdFox
 */

window.yaContextCb = window.yaContextCb || [];
const {adfoxUnits = []} = window.Site;

adfoxUnits
  .filter(slot => !!window[slot.containerId])
  .forEach(slot => {
    const newSlot = {
      ...slot,
      params: {
        ...slot.params,
        ...window.Site.adFoxParams,
        pk: [
          (targeting.category || []).join(' '),
          (targeting.tags || []).join(' '),
          targeting.attr_sponsored_by || '',
          targeting.attr_content_type || ''
        ].filter(Boolean).join(' '),
        pke: '1'
      },
      ...addAdFoxAnalytics(slot.containerId, {
        onRender: () => {
          onSlotRenderEnded(slot.containerId);
        }
      }),
      onLoad: () => {
        startSpecialProjectSlot(slot.containerId);
      }
    };

    /*
     * slot is not rendering branding if it is hidden
     */
    if (document.getElementById(slot.containerId).classList.contains('advert--adfox-hidden')) {
      document.getElementById(slot.containerId).classList.remove('advert--adfox-hidden');
    }

    if (document.getElementById(slot.containerId).classList.contains('advert--hidden')) {
      document.getElementById(slot.containerId).classList.remove('advert--hidden');
    }

    window.yaContextCb.push(() => window.Ya?.adfoxCode.create(newSlot));

    if (newSlot.reloadInterval && newSlot.containerId) {
      startInterval(() => {
        window.Ya?.adfoxCode?.reload(newSlot.containerId, {onlyIfWasVisible: false});
        if (isInViewport(document.querySelector(`#${newSlot.containerId}`))) {
          publishEvent(ANALYTIC_EVENTS.ADFOX_CREATIVE_IMPRESSION, {
            containerId: newSlot.containerId
          });
        }
      }, Number(newSlot.reloadInterval) * MILLISECOND_IN_SECOND);
    }
  });

/*
 * For Branding (Adfox and Google)
 */
window.enableBrandingBehavior = enableBrandingBehavior;
window.enableMobileBrandingBehavior = enableMobileBrandingBehavior;

/*
* lazyloading ads
*/

lazyLoadAds();
